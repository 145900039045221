import React, {useCallback} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";

const PrivateRoute = ({component: Comp, roles, path, ...rest}) =>
{

	const auth = useSelector(
		state => ({
			permission: state.permission,
		})
	);


	const isAuthorized = useCallback(() =>
	{
		if (auth.permission.permission === null)
		{
			return true;
		}
		if (auth.permission.hasOwnProperty(roles))
		{
			if (auth.permission[roles] === true)
			{
				return true;
			}
		}
		return false;
	}, [auth, roles]);


	return (
		<Route
			path={path}
			{...rest}
			render={(props) =>
				isAuthorized()
				? (
					<Comp {...props} />
				)
				: (
					<Redirect
						to={{
							pathname: "/",
							state: {
								prevLocation: path,
							},
						}}
					/>
				)
			}
		/>

	);
};

export default PrivateRoute;

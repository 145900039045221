export const USER_LOAD_START = 'USER_LOAD_START';
export const USER_LOAD_DONE = 'USER_LOAD_DONE';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';

export default function reducer(
    state = {
        data: null,
        loading: false,
        isLoad: false,
        error: null
    },
    action
) {
    switch (action.type) {
        case USER_LOAD_START:
            return {
                loading: true,
                isLoad: false
            };
        case USER_LOAD_DONE:
            return {
                data: action.data,
                privilege: action.privilege,
                loading: false,
                isLoad: true
            };
        case USER_LOAD_ERROR:
            return {
                loading: false,
                isLoad: true,
                error: state.error
            };
        case USER_LOGOUT:
            return {
                loading: false,
                data: null,
                isLoad: true,
                error: state.error
            };
        default:
            return state
    }
}

export default function notificationReducer(
	state = {
		notificationsCount: 0,
		notificationsData: []
	},
	action
)
{
	switch (action.type)
	{
		case 'SET_NOTIFICATION':
			return {
				notificationsCount: action.notificationsCount,
				notificationsData: action.notificationsData
			}
		case 'RESET_NOTIFICATION':
			return {
				notificationsCount: null,
				notificationsData: null
			}
		default:
			return state
	}
}

import {combineReducers} from 'redux'
import configReducer from './reducers/configReducer'
import leftSidebarReducer from './reducers/leftSidebarReducer'
import palettesReducer from './reducers/palettesReducer'
import navigationReducer from './reducers/navigationReducer'
import userReducer from './reducers/userReducer'
import permissionReducer from './reducers/permissionReducer'
import notificationReducer from './reducers/notificationReducer'
import companyReducer from "./reducers/companyReducer";

const rootReducer = combineReducers({
	navigation: navigationReducer,
	config: configReducer,
	leftSidebar: leftSidebarReducer,
	palettes: palettesReducer,
	user: userReducer,
	permission: permissionReducer,
	notification: notificationReducer,
	company: companyReducer
})


export default rootReducer

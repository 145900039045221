import React from 'react'
import {
	FiActivity,
	FiStar,
	FiCompass, FiPhoneCall, FiDatabase, FiUsers, FiShare, FiSettings, FiBarChart, FiPaperclip,
} from 'react-icons/fi';
//import i18n from '../../components/locale/i18n';

//const t = i18n.t;
const initialState = [
	{
		title: 'dashboard',
		items: [
			{
				url: '/',
				icon: <FiCompass size={20}/>,
				title: 'dashboard',
				roles: ['view_dashboard'],
				items: []
			},
		]
	},
	{
		title: 'report',
		items: [
			{
				url: '/calls',
				icon: <FiPhoneCall size={20}/>,
				title: 'call',
				roles: ['view_call'],
				items: []
			},
			{
				url: '/my-tasks',
				icon: <FiPaperclip size={20}/>,
				title: 'task',
				roles: ['view_tasks'],
				items: []
			},
			{
				url: '/my-ratings',
				icon: <FiStar size={20}/>,
				title: 'myRating',
				roles: ['view_my_ratings'],
				items: []
			}
		]
	},
	{
		title: 'overview',
		items: [
			{
				url: '/#',
				icon: <FiActivity size={20}/>,
				title: 'graphicOverview',
				roles: ['view_stats_company'],
				badge: {
					color: 'bg-indigo-500 text-white',
					text: 4
				},
				items: []
			},
			{
				url: '/stats',
				icon: <FiBarChart size={20}/>,
				title: 'yearlyOverview',
				roles: ['view_stats'],
				items: []
			}
		]
	},
	{
		title: 'admin',
		items: [
			{
				url: '/#',
				icon: <FiDatabase size={20}/>,
				title: 'entities',
				badge: {
					color: 'bg-indigo-500 text-white',
					text: 4
				},
				items: [
					{
						url: '/company',
						title: 'company',
						roles: ['view_companies'],
						items: []
					},
					{
						url: '/subject',
						title: 'subject',
						roles: ['view_subjects'],
						items: []
					},
					{
						url: '/topic',
						title: 'topic',
						roles: ['view_topics'],
						items: []
					},
					{
						url: '/country',
						title: 'country',
						roles: ['view_countries'],
						items: []
					},
					{
						url: '/language',
						title: 'language',
						roles: ['view_languages'],
						items: []
					},
					{
						url: '/status',
						title: 'status',
						roles: ['view_statuses'],
						items: []
					},
					{
						url: '/call-type',
						title: 'callType',
						roles: ['view_calltypes'],
						items: []
					},
					{
						url: '/additional-administration',
						title: 'additional',
						roles: ['view_addadmin'],
						items: []
					}
				]
			},
			{
				url: '/rating',
				icon: <FiStar size={20}/>,
				title: 'rating',
				roles: ['view_ratings'],
				items: []
			},
			{
				url: '/export',
				icon: <FiShare size={20}/>,
				title: 'export',
				roles: ['view_exports'],
				items: []
			},
			{
				url: '/settings',
				icon: <FiSettings size={20}/>,
				title: 'settings',
				roles: ['view_settings'],
				items: []
			},
			{
				url: '/user',
				icon: <FiUsers size={20}/>,
				title: 'user',
				roles: ['view_users'],
				items: []
			}
		]
	},
]

const loadState = (companyLoad) =>
{
	return [
		{
			title: 'dashboard',
			items: [
				{
					url: '/',
					icon: <FiCompass size={20}/>,
					title: 'dashboard',
					roles: ['view_dashboard'],
					items: []
				},
			]
		},
		{
			title: 'report',
			items: [
				{
					url: '/calls',
					icon: <FiPhoneCall size={20}/>,
					title: 'call',
					roles: ['view_call'],
					items: []
				},
				{
					url: '/my-tasks',
					icon: <FiPaperclip size={20}/>,
					title: 'task',
					roles: ['view_tasks'],
					items: []
				},
				{
					url: '/my-ratings',
					icon: <FiStar size={20}/>,
					title: 'myRating',
					roles: ['view_my_ratings'],
					items: []
				}
			]
		},
		{
			title: 'overview',
			items: [
				{
					url: '/#',
					icon: <FiActivity size={20}/>,
					title: 'graphicOverview',
					roles: ['view_stats_company'],
					badge: {
						color: 'bg-indigo-500 text-white',
						text: 4
					},
					items: (companyLoad ? companyLoad : [])
				},
				{
					url: '/stats',
					icon: <FiBarChart size={20}/>,
					title: 'yearlyOverview',
					roles: ['view_stats'],
					items: []
				}
			]
		},
		{
			title: 'admin',
			items: [
				{
					url: '/#',
					icon: <FiDatabase size={20}/>,
					title: 'entities',
					badge: {
						color: 'bg-indigo-500 text-white',
						text: 4
					},
					items: [
						{
							url: '/company',
							title: 'company',
							roles: ['view_companies'],
							items: []
						},
						{
							url: '/subject',
							title: 'subject',
							roles: ['view_subjects'],
							items: []
						},
						{
							url: '/topic',
							title: 'topic',
							roles: ['view_topics'],
							items: []
						},
						{
							url: '/country',
							title: 'country',
							roles: ['view_countries'],
							items: []
						},
						{
							url: '/language',
							title: 'language',
							roles: ['view_languages'],
							items: []
						},
						{
							url: '/status',
							title: 'status',
							roles: ['view_statuses'],
							items: []
						},
						{
							url: '/call-type',
							title: 'callType',
							roles: ['view_calltypes'],
							items: []
						},
						{
							url: '/additional-administration',
							title: 'additional',
							roles: ['view_addadmin'],
							items: []
						}
					]
				},
				{
					url: '/rating',
					icon: <FiStar size={20}/>,
					title: 'rating',
					roles: ['view_ratings'],
					items: []
				},
				{
					url: '/export',
					icon: <FiShare size={20}/>,
					title: 'export',
					roles: ['view_exports'],
					items: []
				},
				{
					url: '/settings',
					icon: <FiSettings size={20}/>,
					title: 'settings',
					roles: ['view_settings'],
					items: []
				},
				{
					url: '/user',
					icon: <FiUsers size={20}/>,
					title: 'user',
					roles: ['view_users'],
					items: []
				}
			]
		},
	]
}


export default function navigationReducer(state = initialState, action)
{
	switch (action.type)
	{
		case 'SET_NAVIGATION':
			return loadState(action.navigation)
		default:
			return state
	}
}

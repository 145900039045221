export default function permissionReducer(
    state = {
        permission: null
    },
    action
) {
    switch (action.type) {
        case 'SET_PERMISSION':
            return {
                ...action.permission
            }
        case 'RESET_PERMISSION':
            return {
                permission: null
            }
        default:
            return state
    }
}

import axios from "axios";

export const ApiAxios = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_ENDPOINT,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_API_TOKEN
	},
    credentials: 'include'
});

import {useSelector} from "react-redux";
import {useCallback} from "react";

export default function AuthorizedFunction(roles)
{
	const auth = useSelector(
		state => ({
			permission: state.permission,
		})
	);

	const isAuthorized = useCallback(() =>
	{
		if (auth.permission.permission === null)
		{
			return true;
		}
		if (auth.permission.hasOwnProperty(roles))
		{
			if (auth.permission[roles] === true)
			{
				return true;
			}
		}
		return false;

	}, [auth, roles]);


	return isAuthorized();
}

import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {FiUser, FiLogIn} from 'react-icons/fi'
import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const AccountLinks = () =>
{
	const {t} = useTranslation();
	const history = useHistory();

	const logout = () =>
	{
		ApiAxios.get('/v1/logout')
			.then(res =>
			{
				history.push("/logout");
				toast.success(t('errors.logoutSuccess'));
			})
			.catch(err =>
			{
				toast.error(t('errors.logoutError'));
			});
	};

	const items = [
		{
			url: '/my-profile',
			icon: <FiUser size={18} className="stroke-current"/>,
			name: t('navbar.profile'),
			badge: null
		}
	]

	return (
		<div className="flex flex-col w-full">
			<ul className="list-none">
				{items.map((item, i) => (
					<li key={i} className="dropdown-item">
						<Link
							to={item.url}
							className="flex flex-row items-center justify-start h-10 w-full px-2">
							{item.icon}
							<span className="mx-2">{item.name}</span>
							{item.badge && (
								<span
									className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
							)}
						</Link>
					</li>
				))}
				<li className="dropdown-item">
					<button
						onClick={() => logout()}
						className="flex flex-row items-center justify-start h-10 w-full px-2">
						<FiLogIn size={18} className="stroke-current"/>
						<span className="mx-2">{t('navbar.logout')}</span>
					</button>
				</li>
			</ul>
		</div>
	)
}

export default AccountLinks

import {ApiAxios} from "../../axios/axiosConfig";
import {toast} from "react-toastify";
import i18n from '../../components/locale/i18n';


export function userLogin(data, _this) {
    const t = i18n.t;

    return function (dispatch) {
        console.log(_this);

        ApiAxios.post('/v1/login', data, {
            headers: {
                "LoginToken": process.env.REACT_APP_LOGIN_TOKEN,
                'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_API_TOKEN
            }
        }).then(res => {
            if (res.data) {
                dispatch({type: "USER_LOAD_START"});
                ApiAxios.post('/v1/auth').then(response => {
                    console.log('Authorized 👍');
                    window.location.href = "/";
                }).catch(err => {
                    toast.error(t('errors.loginError'));
                    console.error(err);
                    dispatch({type: "USER_LOGOUT"});
                })
            } else {
                toast.error(t('errors.loginWrongParameters'));
                console.info("Neplatné údaje.");
            }
        }).catch((err) => {
            toast.error(t('errors.loginWrongParameters'));
            console.error(`Chyba: ${err}`);
            dispatch({type: "USER_LOGOUT"});
            //window.location.href = "/login";
        });
    }
}

export function authorize(_this) {
    const t = i18n.t;
    return function (dispatch) {
        dispatch({type: "USER_LOAD_START"});
        ApiAxios.post('/v1/auth').then(response => {
            console.log('Authorized 👍');
            ApiAxios.get(`/v1/company/navigation/select`).then(res => {
                dispatch({
                    type: 'SET_NAVIGATION',
                    navigation: res.data.data
                })
                dispatch({
                    type: 'SET_COMPANIES',
                    companies: res.data.data
                })
            }).catch(err => {
                toast.error(t('errors.notificationCount'));
            });
            dispatch({
                type: "SET_PERMISSION", permission: response.data.data.rights
            })
            dispatch({type: "USER_LOAD_DONE", data: response.data.data, privilege: response.data.privilege})
            dispatch({
                type: "SET_PALETTE", palette: {
                    background: response.data.data.config.background,
                    leftSidebar: response.data.data.config.leftSidebar,
                    navbar: response.data.data.config.navbar,
                    rightSidebar: response.data.data.config.rightSidebar
                }
            })
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    collapsed: response.data.data.config.collapsed
                }
            })

            ApiAxios.get(`/v1/notification/navbar`).then(res => {
                dispatch({
                    type: "SET_NOTIFICATION", notificationsCount: res.data.totalItems,
                    notificationsData: res.data.data
                })
            }).catch(err => {
                toast.error(t('errors.notificationCount'));
            });
        }).catch(err => {
            dispatch({type: "USER_LOGOUT"});
            window.location.href = "/login";
        })
    }
}

export function loginAuth(_this) {
    const t = i18n.t;
    return function (dispatch) {
        dispatch({type: "USER_LOAD_START"});
        ApiAxios.post('/v1/auth').then(response => {
            console.log('Authorized 👍');
            ApiAxios.get(`/v1/company/navigation/select`).then(res => {
                dispatch({
                    type: 'SET_NAVIGATION',
                    navigation: res.data.data
                })
                dispatch({
                    type: 'SET_COMPANIES',
                    companies: res.data.data
                })
            }).catch(err => {
                toast.error(t('errors.notificationCount'));
            });
            dispatch({
                type: "SET_PERMISSION", permission: response.data.data.rights
            })
            dispatch({type: "USER_LOAD_DONE", data: response.data.data, privilege: response.data.privilege})
            dispatch({
                type: "SET_PALETTE", palette: {
                    background: response.data.data.config.background,
                    leftSidebar: response.data.data.config.leftSidebar,
                    navbar: response.data.data.config.navbar,
                    rightSidebar: response.data.data.config.rightSidebar
                }
            })
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    collapsed: response.data.data.config.collapsed
                }
            })

            ApiAxios.get(`/v1/notification/navbar`).then(res => {
                dispatch({
                    type: "SET_NOTIFICATION", notificationsCount: res.data.totalItems,
                    notificationsData: res.data.data
                })
            }).catch(err => {
                toast.error(t('errors.notificationCount'));
            });
            window.location.href = "/"
        }).catch(err => {
            dispatch({type: "USER_LOGOUT"});
        })
    }
}
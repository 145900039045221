import React, {Suspense, lazy} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import PrivateRoute from "./components/utilities/PrivateRoute";
import Loader from "./components/loader/loader";
import {useSelector} from "react-redux";

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Call = lazy(() => import( './pages/Call/Call'));
const Language = lazy(() => import( './pages/Language/Language'));
const LanguageCreate = lazy(() => import( './pages/Language/Create'));
const LanguageEdit = lazy(() => import( './pages/Language/Edit'));
const Country = lazy(() => import( './pages/Country/Country'));
const CountryCreate = lazy(() => import( './pages/Country/Create'));
const CountryEdit = lazy(() => import( './pages/Country/Edit'));
const Company = lazy(() => import( './pages/Company/Company'));
const CompanyCreate = lazy(() => import( './pages/Company/Create'));
const CompanyEdit = lazy(() => import( './pages/Company/Edit'));
const Subject = lazy(() => import( './pages/Subject/Subject'));
const SubjectCreate = lazy(() => import( './pages/Subject/Create'));
const SubjectEdit = lazy(() => import( './pages/Subject/Edit'));
const Topic = lazy(() => import( './pages/Topic/Topic'));
const TopicCreate = lazy(() => import( './pages/Topic/Create'));
const TopicEdit = lazy(() => import( './pages/Topic/Edit'));
const User = lazy(() => import( './pages/User/User'));
const UserCreate = lazy(() => import( './pages/User/Create'));
const UserEdit = lazy(() => import( './pages/User/Edit'));
const Profile = lazy(() => import( './pages/Profile/Profile'));
const PrimaryLogin = lazy(() => import( './pages/Login/Login'));
const Logout = lazy(() => import('./pages/Login/Logout'));
const Reset = lazy(() => import( './pages/ResetPassword/Reset'));
const ResetPassword = lazy(() => import( './pages/ResetPassword/ResetPassword'));
const EmailConfirmation = lazy(() => import('./pages/email-confirmation'));
const Status = lazy(() => import( './pages/Status/Status'));
const StatusCreate = lazy(() => import( './pages/Status/Create'));
const StatusEdit = lazy(() => import( './pages/Status/Edit'));
const CallType = lazy(() => import( './pages/CallType/CallType'));
const CallTypeCreate = lazy(() => import( './pages/CallType/Create'));
const CallTypeEdit = lazy(() => import( './pages/CallType/Edit'));
const AddAdmin = lazy(() => import( './pages/AddAdmin/AddAdmin'));
const AddAdminCreate = lazy(() => import( './pages/AddAdmin/Create'));
const AddAdminEdit = lazy(() => import( './pages/AddAdmin/Edit'));
const Settings = lazy(() => import( './pages/Settings/Settings'));
const CallEdit = lazy(() => import( './pages/Call/Edit'));
const CallCreate = lazy(() => import( './pages/Call/Create'));
const CallComment = lazy(() => import( './pages/Call/Comment'));
const Notification = lazy(() => import( './pages/Notification/Notification'));
const Statistics = lazy(() => import( './pages/Statistics/Statistics'));
const StatisticsCompanies = lazy(() => import( './pages/Statistics/StatisticsCompanies'));
const RatingCreate = lazy(() => import( './pages/Rating/Create'))
const Rating = lazy(() => import( './pages/Rating/Rating'))
const RatingEdit = lazy(() => import( './pages/Rating/Edit'))
const MyRating = lazy(() => import( './pages/Rating/MyRating'))
const RatingDetail = lazy(() => import( './pages/Rating/Detail'))
const MyTask = lazy(() => import( './pages/Task/Task'))
const Export = lazy(() => import( './pages/Export/Export'))

const Routes = () =>
{
	const companies = useSelector(state => state.company.company);
	return (
		<Suspense fallback={<Loader/>}>
			<Switch>
				{/*<Route exact path="/" component={Dashboard}/>*/}
				<PrivateRoute exact path="/" roles={['view_dashboard']} component={Dashboard}/>
				<PrivateRoute exact path="/calls" roles={['view_call']} component={Call}/>

				<PrivateRoute exact path="/language" roles={['view_languages']} component={Language}/>
				<PrivateRoute path="/language/create" roles={['insert_lang']} component={LanguageCreate}/>
				<PrivateRoute path="/language/edit/:languageId" roles={['edit_lang']} component={LanguageEdit}/>

				<PrivateRoute exact path="/country" roles={['view_countries']} component={Country}/>
				<PrivateRoute path="/country/create" roles={['insert_country']} component={CountryCreate}/>
				<PrivateRoute path="/country/edit/:countryId" roles={['edit_country']} component={CountryEdit}/>

				<PrivateRoute exact path="/company" roles={['view_companies']} component={Company}/>
				<PrivateRoute path="/company/create" roles={['insert_company']} component={CompanyCreate}/>
				<PrivateRoute path="/company/edit/:companyId" roles={['edit_company']} component={CompanyEdit}/>

				<PrivateRoute exact path="/subject" roles={['view_subjects']} component={Subject}/>
				<PrivateRoute path="/subject/create" roles={['insert_subject']} component={SubjectCreate}/>
				<PrivateRoute path="/subject/edit/:subjectId" roles={['edit_subject']} component={SubjectEdit}/>

				<PrivateRoute exact path="/topic" roles={['view_topics']} component={Topic}/>
				<PrivateRoute path="/topic/create" roles={['insert_topic']} component={TopicCreate}/>
				<PrivateRoute path="/topic/edit/:topicId" roles={['edit_topic']} component={TopicEdit}/>

				<PrivateRoute exact path="/user" roles={['view_users']} component={User}/>
				<PrivateRoute path="/user/create" roles={['insert_user']} component={UserCreate}/>
				<PrivateRoute path="/user/edit/:userId" roles={['edit_user']} component={UserEdit}/>

				<Route exact path="/my-profile" component={Profile}/>
				<Route exact path="/notifications" component={Notification}/>

				<Route exact path="/forgot-password" component={Reset}/>
				<Route exact path="/reset-password" component={ResetPassword}/>
				<Route exact path="/email-confirmation" component={EmailConfirmation}/>

				<PrivateRoute exact path="/status" roles={['view_statuses']} component={Status}/>
				<PrivateRoute path="/status/create" roles={['insert_status']} component={StatusCreate}/>
				<PrivateRoute path="/status/edit/:statusId" roles={['edit_status']} component={StatusEdit}/>

				<PrivateRoute exact path="/call-type" roles={['view_calltypes']} component={CallType}/>
				<PrivateRoute path="/call-type/create" roles={['insert_calltype']} component={CallTypeCreate}/>
				<PrivateRoute path="/call-type/edit/:callTypeId" roles={['edit_calltype']} component={CallTypeEdit}/>

				<PrivateRoute exact path="/additional-administration" roles={['view_addadmin']} component={AddAdmin}/>
				<PrivateRoute path="/additional-administration/create" roles={['insert_addadmin']}
							  component={AddAdminCreate}/>
				<PrivateRoute path="/additional-administration/edit/:addAdminId" roles={['edit_addadmin']}
							  component={AddAdminEdit}/>

				<PrivateRoute path="/call/create" roles={['insert_call']} component={CallCreate}/>
				<PrivateRoute exact path="/call/edit/:callId" roles={['edit_call']} component={CallEdit}/>
				<PrivateRoute exact path="/call/comment/:callId" roles={['comment_call']} component={CallComment}/>

				<PrivateRoute exact path="/settings" roles={['view_settings']} component={Settings}/>

				<PrivateRoute exact path="/stats" roles={['view_stats']} component={Statistics}/>
				{companies && companies.map((company, key) => {
					return <PrivateRoute key={company.companyId} exact path={company.path} roles={['view_stats_company']} component={StatisticsCompanies}/>
				})}

				<PrivateRoute exact path="/rating/create/:callId" roles={['insert_rating']} component={RatingCreate}/>
				<PrivateRoute exact path="/rating" roles={['view_ratings']} component={Rating}/>
				<PrivateRoute exact path="/rating/edit" roles={['edit_rating']} component={RatingEdit}/>
				<PrivateRoute exact path="/my-ratings" roles={['view_my_ratings']} component={MyRating}/>
				<Route exact path="/rating/detail/:ratingId" component={RatingDetail}/>

				<PrivateRoute exact path="/my-tasks" roles={['view_tasks']} component={MyTask}/>

				<PrivateRoute exact path="/export" roles={['view_exports']} component={Export}/>

				<Route path="/login">
					<PrimaryLogin/>
				</Route>
				<Route path="/logout" component={Logout}/>
				<Redirect to="/"/>
			</Switch>
		</Suspense>
	)
}
export default Routes

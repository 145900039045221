export default function companyReducer(
	state = {
		company: [],
	},
	action
)
{
	switch (action.type)
	{
		case 'SET_COMPANIES':
			return {
				company: action.companies
			}
		default:
			return state
	}
}

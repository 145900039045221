import React, {useState, useRef, useEffect} from 'react'
import {useTranslation} from "react-i18next";
import Czech from "../../assets/czech-republic-svgrepo-com.svg";
import Usa from "../../assets/united-states-of-america-svgrepo-com.svg";
import Ukraine from "../../assets/ukraine-svgrepo-com.svg";

const DropdownLang = () =>
{
	const {i18n} = useTranslation();
	const [hidden, setHidden] = useState(true)
	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)

	useEffect(() =>
	{
		const handleClickOutside = (event) =>
		{
			if (
				hidden ||
				buttonRef.current.contains(event.target) ||
				dropdownRef.current.contains(event.target)
			)
			{
				return false
			}
			setHidden(!hidden)
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () =>
		{
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [hidden, dropdownRef, buttonRef])

	const handleDropdownClick = () =>
	{
		setHidden(!hidden)
	}
	const items = [
		{
			icon: <img src={Czech} alt={"CZ"} className="h-6 w-6"/>,
			name: 'cs',
		},
		{
			icon: <img src={Usa} alt={"US"} className="h-6 w-6"/>,
			name: 'en',
		},
		{
			icon: <img src={Ukraine} alt={"UK"} className="h-6 w-6"/>,
			name: 'ua',
		}
	]
	const returnFlag = (currentLanguage) =>
	{
		if (currentLanguage === "cs")
		{
			return <img src={Czech} alt={"CZ"} className="h-6 w-6"/>;
		}
		if (currentLanguage === "en")
		{
			return <img src={Usa} alt={"US"} className="h-6 w-6"/>;
		}
		if (currentLanguage === "ua")
		{
			return <img src={Ukraine} alt={"UK"} className="h-6 w-6"/>;
		}
	}
	return (
		<div className="hidden lg:flex relative">
			<button
				ref={buttonRef}
				onClick={handleDropdownClick}
				className="flex items-center justify-center h-16 w-12 relative">
				{returnFlag(i18n.resolvedLanguage)}
			</button>
			<div ref={dropdownRef}
				 className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
				<div className="dropdown-content w-10 bottom-start">
					<div className="flex flex-col w-full">
						<ul className="list-none">
							{items.map((item, i) => (
								<li key={i} className="dropdown-item" onClick={() =>
								{
									i18n.changeLanguage(item.name);
								}}>
									<div
										className="flex flex-row items-center justify-start h-10 w-full px-2">
										{item.icon}
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DropdownLang
